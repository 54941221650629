import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import logo from '../images/logo-header.svg';
import { TbGardenCart } from "react-icons/tb";
import { LuUser } from "react-icons/lu";
import { FaRegHeart } from "react-icons/fa";


const Header = () => {
    return (
        <div>
            <div className='main-header fixed-top'>
                <nav className="navbar navbar-expand-lg navbar-light py-0">
                    <div className="container">
                        <NavLink className="navbar-brand" to={'/Home'}>
                            <img src={logo}></img>
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to={'/Home'}>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to={'/About'}>About us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to={'/Shop'}>Shop</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        My Orders
                                    </NavLink>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='ms-0'><Link className="dropdown-item" to={'/MyOrder'}>My Orders</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to={'/ContactUs'}>Contact Us</NavLink>
                                </li>
                            </ul>
                            <form className='d-sm-flex align-items-baseline'>
                                <div className="form-group has-search header-bottom-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <input type="text" className="form-control" placeholder="Search" />
                                </div>
                                <div className='cart-user-heart'>
                                    <Link to={'#'}><LuUser /></Link>
                                    <Link to={'/Wishlist'}><FaRegHeart /></Link>
                                    <Link to={'/Cart'} className='cart-header'><TbGardenCart /><span>3</span></Link><span>Cart</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Header